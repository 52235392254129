import React from 'react';

import logo from "../img/logo.svg";

import { useTranslation } from "react-i18next";

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#7A8FFF] z-10 relative'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
        <img src={logo} alt="" className='h-[50px] xl:ml-0 mt-[20px] xl:mt-0'/>
        <div className='xl:ml-[150px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.1')} <br/>+ 442038469823</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.2')} <br/>Unit GD-GB-00-06-OF-W1, Level 6, Gate Building, DIFC, PO Box 124620, Dubai, UAE</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.3')} <br/>support@{hostname}</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>      
            Legal Status:<br/>
            DIFC Company<br/>
            DFSA Reference Number:<br/>
            F000768</p>
        </div>
        <div className='xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <a href="/Terms.pdf" className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.4')}</a>
          <a href="/policy.pdf" className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.5')}</a>
          <a href="/ANTI-MONEY.pdf" className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer.6')}</a>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[280px] pt-[40px]'>
        <p className='textcolor roboto text-[12px] font-normal mt-[20px] xl:mt-0'>{t('footer.7')}</p>
        <p className='textcolor roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer.8')}</p>
        <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] textcolor pr-[30px] xl:pr-0'>SNB Capital Limited © 2024. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;