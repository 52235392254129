import React, { useState } from "react";

import { useTranslation } from "react-i18next";

function Switcher () {
    
    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    return(
        <div className="max-w-[872px] mx-[20px] xl:mx-auto mt-[40px] xl:mt-[80px] flex flex-col xl:flex-row xl:gap-[40px]">
            <div className="flex flex-col gap-[20px] xl:w-[313px]">
                <div className={`h-[50px] cursor-pointer flex items-center justify-center ${active === 0 ? 'bg-[#D2D9FF]' : 'bg-[#E0E0E0]'}`} onClick={() => setActive(0)}>
                    <p className="roboto text-[16px] text-black text-center">{t('ipo.7')}</p>
                </div>
                <div className={`h-[50px] cursor-pointer  flex items-center justify-center ${active === 1 ? 'bg-[#D2D9FF]' : 'bg-[#E0E0E0]'}`} onClick={() => setActive(1)}>
                    <p className="roboto text-[16px] text-black text-center">{t('ipo.8')}</p>
                </div>
                <div className={`h-[50px] cursor-pointer flex items-center justify-center ${active === 2 ? 'bg-[#D2D9FF]' : 'bg-[#E0E0E0]'}`} onClick={() => setActive(2)}>
                    <p className="roboto text-[16px] text-black text-center">{t('ipo.9')}</p>
                </div>
            </div>
            <div className="mt-[20px] xl:mt-0 xl:max-w-[451px]">
                {active === 0 ? 
                    <div>
                        <p className="text-[#637BFF] text-[16px] xl:text-[30px] open-sans font-[800]">{t('ipo.10')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.11')}</p>
                    </div>
                : 
                    <></>
                }
                {active === 1 ? 
                    <div>
                        <p className="text-[#637BFF] text-[16px] xl:text-[30px] open-sans font-[800]">{t('ipo.12')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.13')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.14')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.15')}</p>
                    </div>
                : 
                    <></>
                }
                {active === 2 ? 
                    <div>
                        <p className="text-[#637BFF] text-[16px] xl:text-[30px] open-sans font-[800]">{t('ipo.16')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.17')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.18')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.19')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.20')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.21')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.22')}</p>
                        <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[488px] leading-[1.5] text-black">{t('ipo.23')}</p>
                    </div>
                : 
                    <></>
                }
            </div>
        </div>
    );
}

export default Switcher;