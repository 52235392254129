import React, { useEffect } from "react";

import Main from '../components/IPO/Main'
import Switcher from "../components/IPO/Switcher";
import Faqs from "../components/IPO/Faqs";

function IPO () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <Switcher/>
            <Faqs/>
        </div>
    );
}

export default IPO;