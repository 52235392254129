import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import arrow from '../../img/TradingInstruments/arrow.svg'

const faqData = [
    {
        question: 'ipo.24',
        answer: 'ipo.25'
    },
    {
        question: 'ipo.27',
        answer: 'ipo.28'
    },
    {
        question: 'ipo.29',
        answer: 'ipo.30'
    },
    {
        question: 'ipo.31',
        answer: 'ipo.32'
    },
    {
        question: 'ipo.33',
        answer: 'ipo.34'
    },
]

function Faqs () {

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[872px] xl:mx-auto mx-[20px] pt-[45px] xl:pt-[90px] pb-[45px] xl:pb-[90px]">
                <div className="flex flex-col">
                    <p className="text-[#637BFF] text-[16px] xl:text-[30px] open-sans font-[800] mx-auto">{t('ipo.26')}</p>
                    <div className="mt-[15px] xl:mt-[30px]">
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div onClick={() => toggleText(index)} className={`flex mt-[20px] transition-all duration-300 ${openIndex === index ? 'bg-[#7A8FFF] rounded-[20px] h-auto' : 'h-[54px] hover:bg-[#DADADA] rounded-[20px] cursor-pointer'}`}>
                                    <div className='cursor-pointer mt-[15px] ml-[20px]'>
                                        <img src={arrow} alt="" className={`${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`}/>
                                    </div>
                                    <div className='mx-[10px] flex flex-col justify-center py-[10px]'>
                                        <p className='font-[600] open-sans text-[14px] xl:text-[16px] text-[#000] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                        <p className='textcolor open-sans text-[14px] xl:text-[16px] font-light mt-[12px] max-w-[200px] xl:max-w-[500px]'>{t(faq.answer)}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Faqs;