import React, { useState } from "react";

import { useTranslation } from "react-i18next";

function Main () {
    
    const { t } = useTranslation();
    
    return(
        <div className="max-w-[872px] mx-[20px] xl:mx-auto mt-[50px] xl:mt-[100px]">
             <p className="text-[#637BFF] text-[16px] xl:text-[30px] open-sans font-[800]">{t('ipo.1')}</p>
             <p className="open-sans text-[16px] font-[300] mt-[15px] xl:mt-[30px] xl:max-w-[872px] leading-[1.5] text-black">{t('ipo.2')}</p>
             <p className="open-sans text-[16px] font-[600] mt-[15px] xl:max-w-[872px] leading-[1.5] text-black">{t('ipo.3')}</p>
             <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[872px] leading-[1.5] text-black">{t('ipo.4')}</p>
             <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[872px] leading-[1.5] text-black">{t('ipo.5')}</p>
             <p className="open-sans text-[16px] font-[300] mt-[15px] xl:max-w-[872px] leading-[1.5] text-black">{t('ipo.6')}</p>
        </div>
    );
}

export default Main;